.hasVat-checkbox .chakra-checkbox__control[data-checked],
.hasVat-checkbox .chakra-checkbox__control[data-checked]:hover {
  background-color: var(--chakra-colors-bg-300);
  border-color: var(--chakra-colors-bg-300);
}
label.chakra-checkbox.hasVat-checkbox {
  display: flex;
  flex-direction: column-reverse;
  align-items: start;
}
.hasVat-checkbox .chakra-checkbox__control {
  width: 50px;
  height: 50px;
  border-radius: var(--chakra-radii-md);
}
.hasVat-checkbox .chakra-checkbox__label {
  margin: 0;
}
